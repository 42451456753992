html,
body {
  margin: 0;
  font-family: 'CircularStd-Regular';
  letter-spacing: -0.03em;
  font-feature-settings: 'salt' on, 'ss01' on, 'ss02' on, 'ss03' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max-width {
  max-width: 1800px;
  margin: auto;
}

.ar-font {
  font-family: 'CircularAr-Regular';
}

.en-font {
  font-family: 'CircularStd-Regular';
}

.ar-font-bold {
  font-family: 'CircularAr-Semibold';
}

.en-font-bold {
  font-family: 'CircularStd-Semibold';
}

.ar-font-bolder {
  font-family: 'CircularAr-Bold';
}

.en-font-bolder {
  font-family: 'CircularStd-Bold';
}

.en-font-default {
  font-feature-settings: normal;
}

.letter-spacing-default {
  letter-spacing: 0 !important;
}

.max-width {
  max-width: 1800px !important;
  margin: auto;
}

@font-face {
  font-family: 'CircularStd-Regular';
  src: local('CircularStd'), url(./assets/fonts/CircularStd-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'CircularStd-Bold';
  src: local('CircularStd'), url(./assets/fonts/CircularStd-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'CircularStd-Semibold';
  src: local('CircularStd'), url(./assets/fonts/CircularStd-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'CircularAr-Regular';
  src: local('CircularAr'), url(./assets/fonts/CircularAr-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'CircularAr-Bold';
  src: local('CircularAr'), url(./assets/fonts/CircularAr-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'CircularAr-Semibold';
  src: local('CircularAr'), url(./assets/fonts/CircularAr-Medium.ttf) format('truetype');
}

/* Tablet */

@media (max-width: 1199.98px) {

  .fs-xl {
    font-size: 64px;
    line-height: 68px;
  }

  .fs-lg {
    font-size: 56px;
    line-height: 60px;
    letter-spacing: -0.03em;
  }

  .fs-md {
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -0.03em;
  }

  .fs-sm {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  .fs-xs {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  .bold-md {
    font-family: "CircularStd-Bold";
  }

  .bold-md-ar {
    font-family: "CircularAr-Bold";
  }

  .semibold-md {
    font-family: "CircularStd-SemiBold";
  }

  .semibold-md-ar {
    font-family: "CircularAr-SemiBold";
  }

  .fs-md-only {
    font-size: 48px;
    line-height: 52px;
  }
}

/* Desktop */

@media (min-width: 1199.98px) {

  .fs-xl {
    font-size: 64px;
    line-height: 68px;
  }

  .fs-lg {
    font-size: 56px;
    line-height: 60px;
    letter-spacing: -0.03em;
  }

  .fs-md {
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -0.03em;
  }

  .fs-sm {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
  }

  .fs-xs {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  .bold-lg {
    font-family: "CircularStd-Bold";
  }

  .bold-lg-ar {
    font-family: "CircularAr-Bold";
  }

  .semibold-lg {
    font-family: "CircularStd-SemiBold";
  }

  .semibold-lg-ar {
    font-family: "CircularAr-SemiBold";
  }
}

/* Mobile */

@media (max-width: 767.98px) {

  .fs-xl {
    font-size: 32px;
    line-height: 36px;
  }

  .fs-lg {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.01em;
  }

  .fs-md {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.01em;
  }

  .fs-sm {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  .fs-xs {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
  }

  .bold-sm {
    font-family: "CircularStd-Bold";
  }

  .bold-sm-ar {
    font-family: "CircularAr-Bold";
  }

  .semibold-sm {
    font-family: "CircularStd-SemiBold";
  }

  .semibold-sm-ar {
    font-family: "CircularAr-SemiBold";
  }
}

.fs-static {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.fs-md-static {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.01em;
}

.fs-xs-static {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
}

.fs-sm-static {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.01em;
}

.fs-lg-static {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.01em;
}